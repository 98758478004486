<template>
  <CHeader dark with-subheader class="bgHeader"   v-bind:style="{ 'background-image': `url(${require('@/assets/images/asb.jpg')})` }">
    <CToggler
      in-header
      light
      class="ml-3 d-lg-none" style="color:#fff; margin-top: 17px;"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="d-lg-none" to="/">
      <span style="font-size:20px;color:#fff">Halo,  {{ nickname }}</span>
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/dashboard">
          Dashboard
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/login">
          Logout
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
    <!-- <CHeaderNav class="mr-4">
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-bell"/>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-list"/>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-envelope-open"/>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <TheHeaderDropdownAccnt/>
    </CHeaderNav> -->

    <div style="height:170px;margin-top: -26px;border: none;"></div>
    <!-- <CSubheader class="px-3" >
    </CSubheader> -->
  </CHeader>
</template>
<style scoped>
  .bgHeader{
    background-color: transparent;border: none;vertical-align: top;align-items: start;
    margin-bottom:-100px;
    z-index: 0;
  }
  .c-header-toggler-icon {
   background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !important;
  }
</style>
<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'

export default {
  name: 'TheHeader',
  data() {
    return {
      itemsCount: 42,
      user : JSON.parse(this.$store.state.users),
      nickname: '',
    };
  },
  mounted(){
      this.nickname = this.user.name
  },
  components: {
    TheHeaderDropdownAccnt
  }
}
</script>
