<template>
  <CSidebar 
    fixed 
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <span style="font-size:20px">Bitqueen</span>
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="nav"/>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav2 from './_nav';
import nav3 from './_nav2';
// let nav;
// let user = JSON.parse(localStorage.getItem('user'));
// let level = user.level;
// // alert(JSON.stringify(user));

// if(level=='1'){
//   nav = nav2;
// }
// else{
//   nav = nav3;
// }
export default {
  name: 'TheSidebar',
  mounted(){
      let us = JSON.parse(this.$store.state.users);
      // alert(us.wallet_faucet);
      if(us.level=='1'){
        this.nav = nav2;
      }
      else{
        this.nav = nav3;
      }
  },
  data() {
    return {
      nav:[]
    };
  },
  // nav,
  computed: {
    show () {
      return this.$store.state.sidebarShow 
    },
    minimize () {
      return this.$store.state.sidebarMinimize 
    }
  }
}
</script>
